import { useCallback, useEffect, useMemo, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import copy from 'copy-to-clipboard';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  COACH_REFLECTION_ON_PLAYER_LESSON_API,
  PostCoachReflectionOnPlayerLessonPayload,
} from 'constants/payloads/coachReflectionOnPlayerLesson';
import { API_ENDPOINT } from 'constants/payloads/lessons/invite';
import { PENDING_COACH_ACTIONS_API } from 'constants/payloads/pendingCoachActions';
import { getCoachFollowUrl } from 'constants/user';
import {
  LessonReflections,
  LessonSkillsEnum,
  UserProfiles,
  useGetCoachAllPlayersReflectionsLazyQuery,
  useGetCoachAllPlayersVideoAnalysisLazyQuery,
  useGetCoachReflectionsBySubmitterIdLazyQuery,
  useGetCoachVideoAnalysisBySubmitterIdLazyQuery,
  useUpdateCoachLessonReflectionMutation,
} from 'types/generated/client';
import { trackEvent } from 'services/client/analytics';
import {
  ONBOARDING_EXAMPLE_REFLECTION_CLICKED,
  ONBOARDING_REMOTE_LESSON_WATCHED,
} from 'services/client/analytics/events';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import { useApiGateway } from 'hooks/useApi';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useMobileView } from 'hooks/useMobileView';
import { useModal } from 'hooks/useModal';
import { useViewer } from 'hooks/useViewer';
import SafeAreaPage from 'layouts/SafeAreaPage';
import ModalCoachQuickOnboard from 'components/modals/ModalCoachQuickOnboard';
import Head from 'components/utilities/Head';
import { CoachPlayerTabHeader, PlayerDetailsSection, SelectPlayersSection } from './components';
import BulkAddPlayersModal, {
  PlayerRow,
} from './components/BulkAddPlayersModal/BulkAddPlayersModal';
import ReflectionFlowModal from './components/ReflectFlowModal';
import { ReflectionFormType } from './components/ReflectFlowModal/props';
import FiltersModal from './components/SelectPlayerSection/FiltersModal';
import { ClientType, Gender, LessonPreference, MobileCoachPlayerTabScreenEnum } from './enums';
import {
  PlayerDetailType,
  PlayerFiltersFormValues,
  PlayerType,
  SelectedPlayerForDetailType,
} from './props';
import { filterPlayers, getFormattedCoachReflectionForFormToEdit } from './utils';

const CoachPlayersTab: React.FC = () => {
  const [selectedPlayerForDetail, setSelectedPlayerForDetail] =
    useState<SelectedPlayerForDetailType>(null);

  const [selectedPlayerForReflection, setSelectedPlayerForReflection] = useState<PlayerType | null>(
    null,
  );

  const [mobileCoachPlayerTabScreen, setMobileCoachPlayerTabScreen] =
    useState<MobileCoachPlayerTabScreenEnum>(MobileCoachPlayerTabScreenEnum.List);

  const [selectedReflectionToEdit, setSelectedReflectionToEdit] =
    useState<ReflectionFormType | null>(null);

  const [followers, setFollowers] = useState<PlayerType[]>([]);

  const { post: postCoachReflection, isLoading: postCoachReflectionLoading } =
    useApiGateway<PostCoachReflectionOnPlayerLessonPayload>(COACH_REFLECTION_ON_PLAYER_LESSON_API);

  const { post: invitePlayer } = useApiGateway(API_ENDPOINT);

  const {
    get: getPendingCoachActions,
    isLoading: followersPendingVideosLoading,
    isCalled: followersPendingVideosCalled,
  } = useApiGateway<never, PlayerType[]>(PENDING_COACH_ACTIONS_API);

  const filterModal = useModal();

  const reflectionModal = useModal();

  const inviteOptionsModal = useModal();

  const bulkAddModal = useModal();

  const [
    getCoachReflectionsBySubmitterId,
    { data: coachReflectionData, loading: getCoachReflectionsLoading },
  ] = useGetCoachReflectionsBySubmitterIdLazyQuery();

  const [
    getCoachAllPlayersReflections,
    { loading: coachAllPlayersReflectionsLoading, data: coachAllPlayersReflectionsData },
  ] = useGetCoachAllPlayersReflectionsLazyQuery();

  const [
    getCoachAllPlayersVideoAnalysis,
    { loading: coachAllPlayersVideoAnalysisLoading, data: coachAllPlayersVideoAnalysisData },
  ] = useGetCoachAllPlayersVideoAnalysisLazyQuery();

  const [
    getCoachVideoAnalysisBySubmitterId,
    { data: coachVideoAnalysisData, loading: coachVideoAnalysisLoading },
  ] = useGetCoachVideoAnalysisBySubmitterIdLazyQuery();

  const [updateCoachLessonReflection, { loading: updateCoachLessonReflectionLoading }] =
    useUpdateCoachLessonReflectionMutation();

  const viewer = useViewer();

  const { user } = useGetCurrentUser();

  const playerFiltersForm = useForm<PlayerFiltersFormValues>({
    defaultValues: {
      clientType: ClientType.All,
      skillLevel: { min: 0, max: 6 },
      gender: Gender.All,
      lessonPreference: LessonPreference.Individual,
      ageRange: { min: 0, max: 99 },
      playerSearchText: '',
    },
  });

  const isMobile = useMobileView();

  const playerFormValues = playerFiltersForm.watch();

  const players = useMemo(
    () => filterPlayers(followers, playerFormValues),
    [followers, playerFormValues],
  );

  const playerDetailData: PlayerDetailType = {
    onCourt: coachReflectionData,
    remote: coachVideoAnalysisData,
  };

  const allPlayerDetailData = {
    onCourt: coachAllPlayersReflectionsData,
    remote: coachAllPlayersVideoAnalysisData,
  };

  const playerDetailLoading = coachVideoAnalysisLoading || getCoachReflectionsLoading;

  const handlePlayerSelect = (selectedPlayer: PlayerType, selectedFor: 'detail' | 'reflection') => {
    if (selectedFor == 'detail') {
      setSelectedPlayerForDetail(selectedPlayer);
      setMobileCoachPlayerTabScreen(MobileCoachPlayerTabScreenEnum.Detail);
    } else {
      reflectionModal.openModal();
      setSelectedPlayerForReflection(selectedPlayer);
    }
  };

  const handleSelectAll = () => {
    setSelectedPlayerForDetail(null);
    setMobileCoachPlayerTabScreen(MobileCoachPlayerTabScreenEnum.Detail);
  };

  const handleAddReflection = async (values: ReflectionFormType) => {
    console.log({ values });
    try {
      const formattedPayload: PostCoachReflectionOnPlayerLessonPayload = {
        submitterUserId: viewer.userId,
        reviewerUserId: selectedPlayerForReflection?.followerUserId,
        lessonId: selectedPlayerForReflection?.pendingAction?.lessonId,
        comments: values.feedback.map((val, index) => {
          return {
            ...val,
            skill: val.skill as LessonSkillsEnum,
            customSkill: val.customSkill,
            order: index + 1,
            userId: viewer.userId,
            lessonReflectionFiles: {
              data: val.lessonReflectionFiles ? [val.lessonReflectionFiles] : [],
            },
          };
        }),
        details: '',
      };

      await postCoachReflection({ payload: formattedPayload });
      await getFollowers();
      if (selectedPlayerForDetail) await handleGetPlayerReflection();
      else await handleGetAllPlayersReflection();
    } catch (err) {
      captureException(err);
      throw err;
    }
  };

  const handleGetPlayerReflection = useCallback(async () => {
    try {
      await getCoachReflectionsBySubmitterId({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        variables: {
          userId: selectedPlayerForDetail?.followerProfile?.id,
          coachId: viewer.userId,
        },
      });
    } catch (err) {
      captureException(err);
    }
  }, [
    viewer.userId,
    selectedPlayerForDetail?.followerProfile?.id,
    getCoachReflectionsBySubmitterId,
  ]);

  const handleGetAllPlayersReflection = useCallback(async () => {
    try {
      await getCoachAllPlayersReflections({
        variables: {
          coachId: viewer.userId,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      });
    } catch (err) {
      captureException(err);
    }
  }, [viewer.userId, getCoachAllPlayersReflections]);

  const getPlayerDetails = useCallback(async () => {
    try {
      await Promise.allSettled([
        getCoachVideoAnalysisBySubmitterId({
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'network-only',
          variables: {
            reviewerId: viewer.userId,
            submissionUserId: selectedPlayerForDetail?.followerProfile?.id,
          },
        }),
        handleGetPlayerReflection(),
      ]);
    } catch (err) {
      captureException(err);
    }
  }, [viewer.userId, selectedPlayerForDetail?.followerProfile?.id]);

  const getAllPlayersDetails = useCallback(async () => {
    try {
      await Promise.allSettled([
        handleGetAllPlayersReflection(),
        getCoachAllPlayersVideoAnalysis({
          variables: {
            reviewerId: viewer.userId,
          },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'network-only',
        }),
      ]);
    } catch (err) {
      captureException(err);
    }
  }, [viewer.userId]);

  const handleReflectOnPlayer = (
    lessonId: string,
    lessonOwner?: UserProfiles | null,
    lessonDate?: string,
    selectedPlayer?: UserProfiles | null,
  ) => {
    trackEvent(ONBOARDING_EXAMPLE_REFLECTION_CLICKED, {
      source: 'onboarding',
      lessonId,
    });

    setSelectedPlayerForReflection(selectedPlayerForDetail);
    let selectedPlayerAsFollower: PlayerType | null = null;
    if (selectedPlayerForDetail) {
      selectedPlayerAsFollower = { ...selectedPlayerForDetail };
    } else if (selectedPlayer) {
      selectedPlayerAsFollower =
        followers.find((follower) => selectedPlayer.id === follower.followerProfile?.id) || null;
    }
    if (!selectedPlayerAsFollower) return;
    const player: PlayerType = {
      ...selectedPlayerAsFollower,
      pendingAction: {
        lessonId,
        coachLessonType: RemoteCoachTypesEnum.Court,
        lessonDate: lessonDate,
      },
    };
    setSelectedPlayerForReflection(player);
    reflectionModal.openModal();
  };

  const handleRemoteLessonWatch = (lessonId: string) => {
    trackEvent(ONBOARDING_REMOTE_LESSON_WATCHED, {
      source: 'onboarding',
      lessonId,
    });
    // ... rest of remote lesson handling
  };

  const getFollowers = useCallback(async () => {
    try {
      const response = await getPendingCoachActions();
      const followers = response?.data as PlayerType[];
      if (followers?.length > 0) setFollowers(followers);
    } catch (error) {
      captureException(error);
    }
  }, [viewer.userId]);

  const handleTriggerEditReflection = (lessonId: string) => {
    const lesson = playerDetailData.onCourt?.lessons.find((lesson) => lesson.id === lessonId);

    const lessonReflection = lesson?.lessonReflections?.[0];

    if (!lessonReflection) return;

    const formattedReflection: ReflectionFormType = getFormattedCoachReflectionForFormToEdit(
      lessonReflection as LessonReflections,
    );

    setSelectedReflectionToEdit(formattedReflection);
    setSelectedPlayerForReflection({
      ...selectedPlayerForDetail,
      pendingAction: {
        lessonId,
        coachLessonType: RemoteCoachTypesEnum.Court,
        lessonDate: lesson.times?.[0]?.startDateTime,
      },
    } as PlayerType);
    reflectionModal.openModal();
  };

  const handleCloseReflectionFlowModal = () => {
    selectedReflectionToEdit && setSelectedReflectionToEdit(null);
    reflectionModal.closeModal();
  };

  const handleEditReflection = async (values: ReflectionFormType) => {
    try {
      const lessonReflection = playerDetailData.onCourt?.lessons.find(
        (lesson) => lesson.id === selectedPlayerForReflection?.pendingAction?.lessonId,
      )?.lessonReflections?.[0];

      if (!lessonReflection) return;

      await updateCoachLessonReflection({
        variables: {
          reflectionId: lessonReflection?.id,
          comments: values.feedback.map((val, index) => ({
            ...val,
            skill: val.skill as LessonSkillsEnum,
            customSkill: val.customSkill,
            order: index + 1,
            userId: viewer.userId,
            lessonReflectionId: lessonReflection?.id,
            lessonReflectionFiles: {
              data: (() => {
                if (val.lessonReflectionFiles) {
                  if (val.lessonReflectionFiles.hasOwnProperty('createdAt')) {
                    delete val.lessonReflectionFiles.createdAt;
                    delete val.lessonReflectionFiles.id;
                    delete val.lessonReflectionFiles.__typename;
                  }
                  return [val.lessonReflectionFiles];
                }
                return [];
              })(),
            },
          })),
        },
      });

      if (selectedPlayerForDetail) await handleGetPlayerReflection();
      else await handleGetAllPlayersReflection();
      handleCloseReflectionFlowModal();
    } catch (error: any) {
      console.error('Edit Reflection Error:', error);
      console.error('Full error details:', {
        values,
        error,
      });
      captureException(error);
    }
  };

  const handleBulkAddPlayers = async (players: PlayerRow[]) => {
    try {
      const promises = players.map((player) =>
        invitePlayer({
          payload: {
            email: player.email,
            fullName: `${player.fullName}`.trim(),
          },
        }),
      );
      await Promise.allSettled(promises);
      await getFollowers();
    } catch (error) {
      captureException(error);
    }
  };

  const handleBack = () => {
    setMobileCoachPlayerTabScreen(MobileCoachPlayerTabScreenEnum.List);
  };

  const handleTriggerShareListing = () => {
    const shareUrl =
      typeof window !== 'undefined' && user
        ? getCoachFollowUrl(user?.username || user?.id || '')
        : '';
    copy(shareUrl);
    toast.success('Share profile link copied');
    if (navigator.share) {
      navigator.share({
        title: `${user?.fullName} - book lessons on Bounce`,
        text: `More details at ${shareUrl}`,
        url: shareUrl,
      });
    }
  };

  const handleInviteClick = () => {
    bulkAddModal.openModal();
  };

  useEffect(() => {
    if (viewer.userId) {
      getFollowers();
    }
  }, [viewer.userId, getFollowers]);

  useEffect(() => {
    if (!viewer.userId) return;
    if (selectedPlayerForDetail) {
      getPlayerDetails();
    } else {
      getAllPlayersDetails();
    }
  }, [selectedPlayerForDetail, viewer.userId, getPlayerDetails, getAllPlayersDetails]);

  return (
    <>
      <Head noIndex title="Clients" description="All the players that follow you" />
      <SafeAreaPage
        isShowTopNav={
          !isMobile || mobileCoachPlayerTabScreen === MobileCoachPlayerTabScreenEnum.Detail
        }
        isHideSidebar
      >
        <CoachPlayerTabHeader handleInvitePlayers={handleInviteClick} />
        <div className="relative flex">
          <SelectPlayersSection
            openFilterModal={filterModal.openModal}
            control={playerFiltersForm.control}
            players={players}
            selectedPlayerForDetail={selectedPlayerForDetail}
            handlePlayerSelect={handlePlayerSelect}
            handleSelectAll={handleSelectAll}
            loading={followersPendingVideosLoading}
            called={followersPendingVideosCalled}
            handleInvitePlayers={handleInviteClick}
            isVisibleOnMobile={mobileCoachPlayerTabScreen === MobileCoachPlayerTabScreenEnum.List}
          />
          <PlayerDetailsSection
            selectedPlayerForDetail={selectedPlayerForDetail}
            handleBack={handleBack}
            loading={playerDetailLoading}
            playerDetailData={playerDetailData}
            allPlayersDetailData={allPlayerDetailData}
            handleReflectOnPlayer={handleReflectOnPlayer}
            handleTriggerEditReflection={handleTriggerEditReflection}
            isVisibleOnMobile={mobileCoachPlayerTabScreen === MobileCoachPlayerTabScreenEnum.Detail}
            totalPlayers={followers.length}
          />
        </div>
      </SafeAreaPage>
      <FiltersModal
        handleClose={filterModal.closeModal}
        open={filterModal.isOpen}
        form={playerFiltersForm}
      />
      <ReflectionFlowModal
        selectedPlayerForReflection={selectedPlayerForReflection}
        handleClose={handleCloseReflectionFlowModal}
        isOpen={reflectionModal.isOpen}
        handleAddReflection={handleAddReflection}
        handleEditReflection={handleEditReflection}
        submitting={postCoachReflectionLoading}
        initialValues={selectedReflectionToEdit}
      />
      <BulkAddPlayersModal
        isOpen={bulkAddModal.isOpen}
        handleClose={bulkAddModal.closeModal}
        onSubmit={handleBulkAddPlayers}
      />
      <ModalCoachQuickOnboard />
    </>
  );
};

export default CoachPlayersTab;
